<template>
    <div
        id="about"
        tag="section"
    >
        <v-container
            class="info-section internal-section"
        >
            <h1>Welcome to T.H.Attorneys</h1><br>
            <div>
                <v-row align="center">
                    <!-- <v-img
                        :src="require('@/assets/th-attorneys-logo-white.png')"
                        alt="T.H.Attorneys Logo"
                        class="mr-5"
                        contain
                        height="250"
                        width="250"
                    /> -->
                    <v-img
                        style="border-radius:10px"
                        :src="require('@/assets/th-logo-black-2.png')"
                        alt="T.H.Attorneys Logo"
                        class="mr-5"
                        contain
                        height="300"
                        width="250"
                    />
                </v-row>
            </div>
            <br>

            <h3>Your legal problem is our concern</h3><br>
            <h3>Your distinguished attorneys of choice</h3><br>

        </v-container>
        <br>

        <v-row>
            <v-col cols="12" md="6">
                <v-container class="info-section internal-section">
                    <p style="text-align:justify">
                    T.H Attorneys was established by Thabile Hlubi in 2021. Driven by 
                    passion for law, she set out to establish a legal practice committed 
                    to excellence. T.H Attorneys has identified the need to provide efficient 
                    and reliable legal solutions. We have strategically positioned and equipped 
                    ourselves to be accessible to small and remote communities and businesses. 
                    We will continue to strive to provide honest, ethical and sound legal services 
                    custom to your legal concerns. The vision will not be 
                    realised until we are a world class practice of your choice.
                </p>
                </v-container>
            </v-col>
            <v-col cols="12" md="6">
                <v-container class="info-section internal-section">
                    <p style="text-align:justify">
                        At T.H Attorneys, we pride ourselves in providing sound legal advise.

                        Our legal team is committed to assist you with professional legal 
                        services relating to the following areas of law:-
                        <ul style="text-align:justify">
                            <li>Litigation</li>
                            <li>Trusts and Estates</li>
                            <li>Family & Matrimonial Law</li>
                            <li>Commercial Law</li>
                            <li>Personal Injury Law</li>
                            <li>Insolvency Law</li>
                            <li>Labour Law</li>
                        </ul>
                    </p>
                </v-container>
            </v-col>
        </v-row>
        <br>


        <v-container class="info-section internal-section">
            <v-row>
                <v-col cols="12" md="6">
                    <v-btn class="action-btns" @click="goToServicesPage()">
                        Our Services
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                    <v-btn class="action-btns" @click="goToContactUsPage()">
                        Get In Touch
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <br>

        <br>

        <ThingsToNote :dialog="open_things_to_note_dialog"/>
        <HowToClaim :dialog="open_how_to_claim_dialog"/>
    </div>
</template>

<script>
import ThingsToNote from "../dialogs/ThingsToNote.vue";
import HowToClaim from "../dialogs/HowToClaim.vue";
import { eventBus } from "../main";

export default {
    name: 'Home',
    components: {
        ThingsToNote,
        HowToClaim
    },
    data: () => ({
        open_things_to_note_dialog: false,
        open_how_to_claim_dialog: false
    }),
    methods: {
        openThingsToNoteDialog() {
            this.open_things_to_note_dialog = true;
        },
        closeThingsToNoteDialog() {
            this.open_things_to_note_dialog = false;
        },

        openHowToClaimDialog() {
            this.open_how_to_claim_dialog = true;
        },
        closeHowToClaimDialog() {
            this.open_how_to_claim_dialog = false;
        },

        goToServicesPage() {
            this.$router.push('/services');
        },
        goToContactUsPage() {
            this.$router.push('/contact-us');
        }
    },
    mounted() {
        eventBus.$on('CLOSE_THINGS_TO_NOTE_DIALOG', this.closeThingsToNoteDialog);
        eventBus.$on('CLOSE_HOW_TO_CLAIM_DIALOG', this.closeHowToClaimDialog);
    }
}
</script>
<style scoped>
.action-btns {
    /* background: #f8c412 !important; */
    /* background: transparent !important; */
    background: #000000 !important;
    border: 2px solid #f6b11a;
    color: #f6b11a !important;
    border-radius: 0px;
}

.internal-section {
    color: #000000 !important;
    font-weight: bold;
    border-radius: 20px;
    height: 100%;
    max-height: 1000px;
}

</style>
