<template>
    <div>
        <v-container class="info-section" style="border-radius: 20px;">
            <h1>About Us</h1>
        </v-container>
        <br>
        <v-row>
            <v-col cols="12" md="6">
                <v-container class="info-section internal-section">
                    <h1>The Founder</h1>
                    <v-divider></v-divider>
                    <br>
                    <p style="text-align:justify">
                        T.H Attorneys was established by Thabile Hlubi in 2021. Driven by 
                        passion for law, she set out to establish a legal practice committed 
                        to excellence. T.H Attorneys has identified the need to provide efficient 
                        and reliable legal solutions. We have strategically positioned and equipped 
                        ourselves to be accessible to small and remote communities and businesses. 
                        We will continue to strive to provide honest, ethical and sound legal services 
                        custom to your legal concerns. The vision will not be 
                        realised until we are a world class practice of your choice.
                    </p>
                </v-container>
                <br>
            </v-col>

            <v-col cols="12" md="6">
            <v-container class="info-section internal-section" style="text-align:justify">
                <h1 style="text-align:center">Our Philosophy</h1>
                <v-divider></v-divider>
                <br>

                <p>
                    <ul>
                        <li>
                            “Audi alteram partem” a rule of wisdom and law, meaning; 
                            “let the other side be heard”  and we want to hear you. We believe in 
                            equality and justice for all.
                        </li>
                        <li>
                            We are here to listen to your side of the story and provide you 
                            with the professional and ethical  legal solutions you deserve.
                        </li>
                        <li>
                            We stand for excellence and justice, as such we have committed 
                            ourselves to provide efficient and cost effective legal solutions 
                            customised to solve your legal challenges.
                        </li>
                    </ul>
                </p>
            </v-container>
            <br>
            </v-col>
        </v-row>
    </div>
</template>

<script>
  export default {
    name: 'AboutUs',
  }
</script>
<style scoped>
.internal-section {
    color: #000000 !important;
    font-weight: bold;
    border-radius: 20px;
    height: 100%;
    max-height: 1000px;
}
</style>