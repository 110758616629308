<template>
    <div>
        <v-container class="info-section" style="border-radius: 20px;">
            <h1>Media</h1>
            <v-divider></v-divider>
            <br>
            <p>
                Legal contect simplified
            </p>
        </v-container>
        <br>

        <v-row>
            <v-col cols="12" md="6">
                <v-container class="service">
                    <h2>Unjustified Enrichment Explained</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        <iframe
                            style="border:0; width: 100%; height: 384px;"
                            src="https://www.youtube.com/embed/ONDZCBoIZJ4">
                        </iframe>
                    </p>
                </v-container>
            </v-col>
            <v-col cols="12" md="6">
                <v-container class="service">
                    <h2>Matrimonial Regimes in S.A</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        <iframe
                            style="border:0; width: 100%; height: 384px;"
                            src="https://www.youtube.com/embed/1qoxKKZCqFk">
                        </iframe>
                    </p>
                </v-container>
            </v-col>
            <v-col cols="12" md="6">
                <v-container class="service">
                    <h2>Wills Week 2021</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        <iframe 
                            src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/173648380890366/videos/526052471819933/&show_text=0&width=560"
                            style="border:0; width: 100%; height: 384px;"
                            scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"
                        ></iframe>
                        <!-- <iframe
                            style="border:0; width: 100%; height: 384px;"
                            src="https://www.youtube.com/embed/1qoxKKZCqFk">
                        </iframe> -->
                    </p>
                </v-container>
            </v-col>
        </v-row>
        <br>
        <br>
    </div>
</template>

<script>
// import PersonalCoverComponent from "../components/PersonalCoverComponent.vue";
export default {
    name: 'PersonalCoverPage',
    components: {
        // PersonalCoverComponent,
    },

    data: () => ({
    }),
    methods: {
    },
    mounted() {
        
    }
}
</script>
<style scoped>
.info-section-override {
    background: #4791a0c2;
    color: #ffffff;
    text-align: center;
    padding: 30px !important;
}


.service {
    background: #cc992ab0;
    color: #000000;
    font-weight:500;
    text-align: center;
    padding: 30px !important;
    border-radius: 20px;

    height: 100%;
    max-height: 1000px;
}

.internal-section {
    color: #000000 !important;
    font-weight: bold;
    border-radius: 20px;
    height: 100%;
    max-height: 1000px;
}
</style>