<template>
    <div>
        <v-container class="info-section internal-section">
            <h1>Contact Us</h1>
            <v-divider></v-divider>
            <br>

            <p>Please feel free to get in touch with us</p>
        </v-container>
        <br>

        <!-- <v-container
            id="about"
            tag="section"
            class="info-section internal-section"
        > -->
        <v-row>
            <v-col cols="12" md="6">
                <v-container
                    class="service info-section internal-section"
                    style="text-align:justify"
                >
                    <h1>Get In Touch</h1>
                    <v-divider></v-divider>
                    <br>

                    <!-- Form -->
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <v-text-field
                                v-model="contact.name"
                                autocomplete="cancel-autocomplete"
                                label="First name"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <v-text-field
                                v-model="contact.surname"
                                autocomplete="cancel-autocomplete"
                                label="Last name"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="contact.phone"
                                autocomplete="cancel-autocomplete"
                                label="Phone"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="contact.email"
                                autocomplete="cancel-autocomplete"
                                label="E-mail"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-textarea
                                v-model="contact.message"
                                autocomplete="cancel-autocomplete"
                                name="input-7-1"
                                label="Message"
                                hint="Message"
                            ></v-textarea>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-btn block class="action-btns" @click="submitToServer()">
                                Send Message
                            </v-btn>
                        </v-col>


                        <v-row v-if="submitting" style="text-align:center">
                            <v-col cols="12" sm="11" md="12">
                                <h3>Submitting your details ...</h3>
                                <v-progress-linear
                                    color="black"
                                    class="mb-0"
                                    height="5"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-container>
            </v-col>

            <v-col cols="12" md="6">
                <v-container
                    class="service info-section internal-section"
                    style="text-align:justify"
                >
                    <h1>Our Address</h1>
                    <v-divider></v-divider>
                    <br>

                    <div>
                        <p>
                        Block B, Metropolitan Building<br/>
                        8 Hillside Rd, Parktown<br/>
                        2193</p>
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCFOJ2rxRi0z4b7rWDzJZahwjQwruBFDW8&q=Regus+-+Johannesburg,+Parktown"
                        frameborder="0"
                        style="border:0; width: 100%; height: 384px;"
                        allowfullscreen
                    ></iframe>
                </v-container>
            </v-col>
        </v-row>
        <!-- </v-container> -->
    </div>
</template>

<script>
import { submitToBackend, emitAlert, emitSuccess } from "../utils/api";
  export default {
    name: 'ContactUs',
    data: () => ({
        submitting: false,
        contact: {
            name: '',
            surname: '',
            phone: '',
            email: '',
            message: ''
        },
    }),
    methods: {
        resetForm() {
            this.submitting = false;
            this.contact = {
                name: '',
                surname: '',
                phone: '',
                email: '',
                message: ''
            };
        },
        async submitToServer() {
            if (
                this.contact.name.trim().length === 0 ||
                this.contact.surname.trim().length === 0 ||
                this.contact.phone.trim().length === 0 ||
                this.contact.email.trim().length === 0 ||
                this.contact.message.trim().length === 0
            ) {
                emitAlert('Please capture all the details required');
                return;
            }

            this.submitting = true;
            const payloadObj = {
                type: 'WEBSITE_INQUIRY',
                details: {
                    ...this.contact
                }
            };

            try {
                // First create the business_partner
                const res = await submitToBackend(payloadObj);

                emitSuccess(res.message);
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }

                return;
            }
        }
    }
  }
</script>
<style scoped>
.info-section {
    text-align: left !important;
}
.internal-section {
    color: #000000 !important;
    font-weight: bold;
    border-radius: 20px;
    height: 100%;
    max-height: 1000px;
}
.action-btns {
    /* background: #f8c412 !important; */
    /* background: transparent !important; */
    background: #000000 !important;
    border: 2px solid #f6b11a;
    color: #f6b11a !important;
    border-radius: 0px;
}
</style>
