<template>
    <v-app>
        <v-snackbar
            :value="hideAlert"
            :color="alert.color"
            :timeout="alert.timeout"
            @input="resetSnackBarValue"
            multi-line
            top
        >
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ alert.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ alert.title }}</strong>
                    </div>
                    <div>{{ alert.message }}</div>
                </v-layout>
            </v-layout>

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    icon
                    dark
                    text
                    v-bind="attrs"
                    @click="resetSnackBarValue"
                >
                    <v-icon>clear</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-app-bar
            app
            color="amber"
            fixed
        >
            <!-- ONLY SHOW THE FOLLOWING ON SMALL SCREENS (by using class="hidden-md-and-up") -->
            <v-app-bar-nav-icon
                color="#000000"
                class="hidden-md-and-up"
                @click="openDrawer()"
            ></v-app-bar-nav-icon>
            <span
                class="title ml-3 mr-5 hidden-md-and-up"
            >
                <span style="color:#000000">
                    T.H.Attorneys&nbsp;
                </span>
            </span>
            
            <!-- <v-spacer></v-spacer> -->



            <!-- <v-container class="mx-auto py-0">
                <v-row>
                    <v-col cols="12" md="12" sm="12" lg="6" offset-lg="3">
                        <v-bottom-navigation
                            v-model="nav_value"
                            class="hidden-sm-and-down"
                            background-color="transparent"
                        >
                            <v-spacer></v-spacer>
                            <v-img
                                :src="require('@/assets/th-logo-no-text.png')"
                                alt="T.H. Attorneys Logo"
                                class="mr-5"
                                contain
                                height="55"
                                width="55"
                                max-width="60"
                                link
                                @click="navigate('/')"
                            />

                            <v-btn
                                value="home"
                                @click="navigate('/')"
                            >
                                <h3>Home</h3>
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn
                                value="aboutus"
                                @click="navigate('/about-us')"
                            >
                                <h3>About Us</h3>
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn
                                value="services"
                                @click="navigate('/services')"
                            >
                                <h3>Services</h3>
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn
                                value="contactus"
                                @click="navigate('/contact-us')"
                            >
                                <h3>Contact Us</h3>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-bottom-navigation>
                    </v-col>
                </v-row>
            </v-container> -->


            <!-- <v-app-bar-nav-icon
                color="#000000"
                class="hidden-sm-and-down"
                @click="navigate('/')"
            > -->
            <v-spacer></v-spacer>
                <v-img
                    :src="require('@/assets/th-logo-black-no-text.png')"
                    alt="T.H. Attorneys Logo"
                    class="mr-5 hidden-sm-and-down"
                    contain
                    height="55"
                    width="55"
                    max-width="60"
                    link
                    @click="navigate('/')"
                />
                <!-- <v-toolbar-title
                    style="color:black; z-index:999999"
                    class="hidden-sm-and-down"
                >
                    <b>T.H.Attorneys</b>
                </v-toolbar-title> -->
                <span
                    class="title ml-3 mr-5 hidden-sm-and-down"
                >
                    <span style="color:#000000">
                        T.H.Attorneys&nbsp;
                    </span>
                </span>
            <!-- </v-app-bar-nav-icon> -->
            <v-spacer></v-spacer>



            <!-- THE FOLLOWING NAV BUTTONS ARE THE DEFAULT VIEW -->
            <!-- <v-container class="mx-auto py-0"> -->
            <v-container class="mx-auto py-0">
                <!-- HIDE THIS DEFAULT VIEW FOR SMALL SCREENS BY USING class="hidden-sm-and-down" -->
                <!-- <v-row>
                    <v-col cols="12" lg="6" offset-lg="3"> -->
                        <!-- <v-spacer></v-spacer> -->
                        <v-bottom-navigation
                            v-model="nav_value"
                            class="hidden-sm-and-down"
                            background-color="transparent"
                            color="transparent"
                            style="box-shadow:none"
                            grow
                        >
                            <!-- <v-spacer></v-spacer>
                            <v-spacer></v-spacer> -->
                            <!-- <v-img
                                :src="require('@/assets/th-logo-no-text.png')"
                                alt="T.H. Attorneys Logo"
                                class="mr-5"
                                contain
                                height="55"
                                width="55"
                                max-width="60"
                                link
                                @click="navigate('/')"
                            /> -->

                            <v-btn
                                value="home"
                                @click="navigate('/')"
                            >
                                <h3>Home</h3>
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->

                            <v-btn
                                value="aboutus"
                                @click="navigate('/about-us')"
                            >
                                <h3>About Us</h3>
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->

                            <v-btn
                                value="services"
                                @click="navigate('/services')"
                            >
                                <h3>Services</h3>
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->

                            <v-btn
                                value="contactus"
                                @click="navigate('/contact-us')"
                            >
                                <h3>Contact Us</h3>
                            </v-btn>
                            <!-- <v-spacer></v-spacer> -->

                             <v-btn
                                value="media"
                                @click="navigate('/media')"
                            >
                                <h3>Media</h3>
                            </v-btn>
                        </v-bottom-navigation>
                    <!-- </v-col>
                </v-row> -->
            </v-container>
        </v-app-bar>


        <!-------------------------- NAVIGATION DRAWER FOR SMALL SCREENS -------------------------->
        <v-navigation-drawer
            v-model="drawer"
            app
            clipped
            style="background: #cc982b"
        >
            <v-list-item link @click="navigate('/')">
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        <v-img
                            src="@/assets/th-logo-black-2.png"
                            alt="T.H.Attorneys Logo"
                            width="70%"
                        />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                dense
                class="#cc982b"
            >
                <template v-for="(item, i) in items">
                    <!-- Menu Item Heading -->
                    <v-row
                        v-if="item.heading"
                        :key="i + '-heading-' + item.heading"
                        align="center"
                    >
                        <v-col cols="12" style="padding-top:0px">
                            <v-subheader v-if="item.heading && item.display===true">
                                <b>{{ item.heading }}</b>
                            </v-subheader>
                        </v-col>
                    </v-row>
                    <!-- Menu item divider -->
                    <v-divider
                        v-if="item.divider === true && item.display === true"
                        :key="i  + '-divider'"
                        dark
                        class="my-4"
                    ></v-divider>
                    <!-- Menu item -->
                    <div v-else-if="item.display === true && item.path" :key="i + 'menu'">
                        <v-list-item
                            link
                            @click="navigate(item.path)"
                            :style="$route.path===item.path ? 'background: #000000; color:#ffffff' : ''"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main :class="container_css">
            <v-container>
                <keep-alive>
                    <v-scale-transition mode="out-in">
                        <router-view :key="$route.fullPath"/>
                    </v-scale-transition>
                </keep-alive>
            </v-container>
        </v-main>

        <!-- <v-footer app> -->
        <v-footer padless class="site-footer">
            <v-container>
                <br>
                <v-row style="background: #cc982a; color:#000000; border-radius:10px">
                    <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        class="text-center"
                    >
                        <v-list style="background: #cc982a; color:#000000">
                            <h1>T.H.Attorneys</h1>
                            <v-divider></v-divider>

                            <v-list-item>
                                <v-list-item-avatar color="primary">
                                    <v-icon
                                        class="elevation-4"
                                        color="amber"
                                    >
                                        mdi-email-outline
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title >
                                        Email
                                    </v-list-item-title>

                                    <v-list-item-subtitle class="text-wrap">
                                        info@thattorneys.co.za
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item>
                                <v-list-item-avatar color="primary">
                                    <v-icon
                                        class="elevation-4"
                                        color="amber"
                                    >
                                        mdi-phone
                                    </v-icon>
                                </v-list-item-avatar>

                                <!-- <v-list-item-title>Phone</v-list-item-title>

                                <v-list-item-subtitle class="text-wrap">
                                    +27 84 421 5102
                                </v-list-item-subtitle> -->

                                <v-list-item-content>
                                    <v-list-item-title>Phone</v-list-item-title>

                                    <v-list-item-subtitle class="text-wrap">
                                        +27 84 421 5102
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item>
                                <v-list-item-avatar color="primary" style="text-align:right">
                                    <v-icon
                                        class="elevation-4"
                                        color="amber"
                                    >
                                        mdi-map-marker
                                    </v-icon>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title>Address</v-list-item-title>

                                    <v-list-item-subtitle class="text-wrap">
                                        <div>Block B, Metropolitan Building</div>
                                        <div>8 Hillside Rd, Parktown</div>
                                        <div>2193</div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <br>
                            <v-divider></v-divider>

                            <v-list-item>
                                <v-row
                                    align="center"
                                    justify="space-around"
                                >
                                    <v-col
                                        cols="12"
                                        class="text-center"
                                    >
                                        <v-btn
                                            fab
                                            medium
                                            depressed
                                            color="primary"
                                            @click="openFacebook()"
                                        >
                                            <v-icon large color="#f6b11a">mdi-facebook</v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            medium
                                            depressed
                                            color="primary"
                                            @click="openLinkedin()"
                                        >
                                            <v-icon large color="#f6b11a">mdi-linkedin</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
            
            <v-col class="text-center" cols="12" style="color:#f6b11a;">
                Copyright &copy; <strong>T.H.Attorneys</strong> {{ new Date().getFullYear() }}
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
import { eventBus } from "./main";
import alert_mixin from "./mixins/alerts";

export default {
    name: 'App',

    mixins: [alert_mixin],

    components: {
        // HelloWorld,
    },

    data: () => ({
        //
        drawer: false,

        items: [
            { text: 'Home', path: '/', display: true },
            { text: 'About Us', path: '/about-us', display: true },
            { text: 'Services', path: '/services', display: true },
            { text: 'Contact Us', path: '/contact-us', display: true },
            { text: 'Media', path: '/media', display: true },
        ],
    }),
    methods: {
        resetSnackBarValue() {
            // Reset snackbar alert so it can show again when another error is triggered
            eventBus.$emit('resetAlert');
        },

        openDrawer() {
            this.drawer = !this.drawer;
        },
        navigate(path) {
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        },

        openFacebook() {
            // https://www.facebook.com/TH-Attorneys-173648380890366
            window.open("https://www.facebook.com/TH-Attorneys-173648380890366", "_blank");
        },
        openLinkedin() {
            window.open("https://www.linkedin.com/in/thabile-hlubi-6b644b152/", "_blank");
        }
    },

    computed: {
        container_css() {
            switch (this.$route.path) {
                case '/':
                    return 'container-background-1';
                case '/about-us':
                    return 'container-background-2';
                case '/services':
                    return 'container-background-3';
                case '/contact-us':
                    return 'container-background-4';
                default:
                    return 'container-background-1'
            }
        },
        nav_value: {
            get: function() {
                switch (this.$route.path) {
                    case '/':
                        return 'home';
                    case '/about-us':
                        return 'aboutus';
                    case '/services':
                        return 'services';
                    case '/contact-us':
                        return 'contactus';
                    case '/media':
                        return 'media';
                    default:
                        return 'home'
                }
            },
            set: function() {},
        },
    }
};
</script>
<style>
    .container-background-1 {
        /* background:url('./assets/background-consulting.jpeg') no-repeat center center fixed; */
        background:url('./assets/background-consulting.png') no-repeat center center fixed;
        /* background:url('./assets/from-thabile/con-court-outside.png') no-repeat center center fixed; */
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .container-background-2 {
        background:url('./assets/about.jpeg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .container-background-3 {
        /* background:url('./assets/background3-min.jpeg') no-repeat center center fixed; */
        background:url('./assets/background3.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .container-background-4 {
        /* background:url('./assets/background4-min.jpeg') no-repeat center center fixed; */
        background:url('./assets/background4.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .top-menu {
        background: #f6c20fba;
    }

    .info-section {
        background: #cc992ab0;
        /* background: #444444cc; */
        color: #000000;
        /* color: #ffffff; */
        text-align: center;
        padding: 30px !important;
    }

    .site-footer {
        background: #000000 !important;
        /* background: #2d2d2f !important; */
        color: #000000 !important;
        border: 1px solid #f8c412 !important;
    }

    /* Navigation Active Btns */
    .v-item-group.v-bottom-navigation .v-btn.v-btn--active {
        color: #f6b11a !important;
        /* color: #ffffff !important; */
        /* background: #bb8614 !important; */
        background: #000000 !important;
    }

    /* CHnage selection */
    ::-moz-selection {
        /* Code for Firefox */
        color: #ffffff;
        background: #cc982b;
    }

        ::selection {
        color: #ffffff;
        background: #cc982b;
    }
</style>
