<template>
    <div>
        <v-container class="info-section" style="border-radius: 20px;">
            <h1>Our Services</h1>
        </v-container>
        <br>

        <v-row>
            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Litigation</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Urgent applications for interdictory relief</li>
                            <li style="text-align:left">General Litigation</li>
                            <li style="text-align:left">Drafting Legal Opinion</li>
                            <li style="text-align:left">Commercial Litigation</li>
                        </ul>
                    </p>
                    <p>
                        Litigation is the process of taking legal action and settling 
                        disputes in a court of law. Our dedicated litigation department 
                        will thoroughly consult with the aggrieved client to determine 
                        whether the client has a right at law. After an extensive application 
                        of legislation, case law and the rules of court, we will proceed 
                        to advise our clients on the most practical and efficient remedy 
                        to seek from the courts. A similar approach is taken in defending 
                        clients whom legal action has been taken against. You can rely on 
                        T.H Attorneys to assist you in High Court and Magistrate Court 
                        Civil Litigation.
                    </p>
                </v-container>
            </v-col>
            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Trust Estates And Wills</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Urgent applications for interdictory relief</li>
                            <li style="text-align:left">Estate Administration</li>
                            <li style="text-align:left">Drafting Wills</li>
                            <li style="text-align:left">Estate Litigation</li>
                            <li style="text-align:left">Curatorship Applications</li>
                            <li style="text-align:left">Trust Registrations and Trust Amendments</li>
                            <li style="text-align:left">Trust Litigation</li>
                        </ul>
                    </p>
                    <p>
                        Death is an inevitable and emotional part of life, that is why
                         interpreting wills and administrating deceased estates should
                          be your last concern. Our dynamic estates department will assist 
                          you in reporting an estate, obtaining letters of executorship, 
                          drafting liquidation and distribution accounts and the distribution 
                          of the estate. Our team is equipped to provide sound legal advise 
                          and assist in the administration of deceased estates and inter-vivos 
                          trusts. 
                    </p>
                </v-container>
            </v-col>

            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Family & Matrimonial Law</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Unopposed & Opposed Divorce</li>
                            <li style="text-align:left">Antenuptial Contracts</li>
                            <li style="text-align:left">Co-habitation Agreements</li>
                        </ul>
                    </p>
                    <p>
                        At T.H Attorneys we understand the emotional and financial 
                        strain that comes with the dissolution of a marriage. We have 
                        the necessary skill and experience to approach such sensitive 
                        matters in a delicate yet practical manner that is tailor made 
                        for each client. We are equipped to guide you through contested, 
                        mediated and uncontested divorce.
                    </p>
                </v-container>
            </v-col>
            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Commercial Law</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Business Contracts</li>
                            <li style="text-align:left">Sales Agreements</li>
                            <li style="text-align:left">Registration of Memorandum of Incorporations, Company Resolutions & Shareholders’ Agreements</li>
                        </ul>
                    </p>
                    <p>
                        At T.H Attorneys we believe that, your business will rise or fall 
                        based on the relationships and the agreements you have in place. 
                        We care about our clients, that is why, we want to ensure their 
                        interests are protected by reducing the casualties that come 
                        with conducting a successful business.
                    </p>
                    <p>
                        Regardless of the size; large, medium and small business alike, 
                        we are equipped to assist and advise  you on contracting and 
                        conducting sound business practices.
                    </p>
                </v-container>
            </v-col>

            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Personal Injury</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Motor Vehicle Accident</li>
                            <li style="text-align:left">Slip and Fall Injuries</li>
                            <li style="text-align:left">Medical Negligence</li>
                        </ul>
                    </p>
                    <p>
                        Have you suffered an injury, construction site accident, slip 
                        and fall or dog bite maybe? Claiming damages in such matters 
                        can be quite complex, as these are time sensitive matters. 
                        Clients often wait until the last hour to institute such 
                        proceedings and more often than not, the claim has prescribed. 
                        We have extensive knowledge and experience in this area of law, 
                        you can rely on us to enforce a claim on your behalf. We are 
                        equipped to advise you on how to proceed in claiming damages 
                        against the party legally responsible for your injuries. In the 
                        alternative, assisting you in defending a personal injury claim.
                    </p>
                </v-container>
            </v-col>
            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Labour Law</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Contractual-Related Services</li>
                            <li style="text-align:left">Internal disciplinary related matters</li>
                            <li style="text-align:left">CCMA, Bargaining Council</li>
                            <li style="text-align:left">Labour Court or High Court litigation</li>
                        </ul>
                    </p>
                    <p>
                        Covid-19 taught us that nothing is guaranteed, more so the 
                        monthly salary that you heavily depend on, employee or employer 
                        alike, all were affected. That is why we have pledged ourselves 
                        to ensure that you receive the best solution whatever the 
                        circumstance.
                    </p>
                    <p>
                        We are equipped to represent and assist employees and employers 
                        in labour matters. Whether it is referring and arguing matters 
                        at the CCMA, Bargaining Councils, and Labour Court. You can 
                        rely on us to ensure you achieve optimum results in your case. 
                    </p>
                </v-container>
            </v-col>

            <v-col cols="12" md="6">
                <v-container class="service" style="text-align:justify">
                    <h2>Insolvency Law</h2>
                    <v-divider></v-divider>
                    <br>
                    <p>
                        Our services include but are not limited to:
                        <ul>
                            <li style="text-align:left">Sequestrations</li>
                            <li style="text-align:left">Liquidations</li>
                            <li style="text-align:left">Administration of Insolvent Estates</li>
                        </ul>
                    </p>
                    <p>
                        At T.H Attorneys we view insolvency as a chance to learn 
                        from financial errors and pave a  new path  towards financial 
                        growth. 

                        In the unfortunate circumstance where you find yourself or your 
                        business under financial distress. We will be ready to guide you 
                        in deciding and finding the most practical options for your 
                        estate, company and creditors.
                    </p>
                    <p>
                        We are equipped to represent and assist employees and employers 
                        in labour matters. Whether it is referring and arguing matters 
                        at the CCMA, Bargaining Councils, and Labour Court. You can 
                        rely on us to ensure you achieve optimum results in your case. 
                    </p>
                </v-container>
            </v-col>
        </v-row>
        <br>

        <!-- <v-container class="info-section">
            <h1>Personal Cover Packages</h1>

            <v-row>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Bronze Package" ranking="bronze"/>
                </v-col>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Silver Package" ranking="silver"/>
                </v-col>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Gold Package" ranking="gold"/>
                </v-col>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Platinum Package" ranking="platinum"/>
                </v-col>
            </v-row>
        </v-container> -->
        <br>
    </div>
</template>

<script>
// import PersonalCoverComponent from "../components/PersonalCoverComponent.vue";
export default {
    name: 'PersonalCoverPage',
    components: {
        // PersonalCoverComponent,
    },

    data: () => ({
    }),
    methods: {
    },
    mounted() {
        
    }
}
</script>
<style scoped>
.info-section-override {
    background: #4791a0c2;
    color: #ffffff;
    text-align: center;
    padding: 30px !important;
}


.service {
    background: #cc992ab0;
    color: #000000;
    font-weight:500;
    text-align: center;
    padding: 30px !important;
    border-radius: 20px;

    height: 100%;
    max-height: 1000px;
}

.internal-section {
    color: #000000 !important;
    font-weight: bold;
    border-radius: 20px;
    height: 100%;
    max-height: 1000px;
}
</style>