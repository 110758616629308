import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './pages/Home.vue';
import AboutUs from './pages/AboutUs.vue';
import Services from './pages/Services.vue';
import ContactUs from './pages/ContactUs.vue';
import Media from './pages/Media.vue';


Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            meta: { keepAlive: true }
        },
        {
            path: '/about-us',
            component: AboutUs,
            meta: { keepAlive: true }
        },
        {
            path: '/services',
            component: Services,
            meta: { keepAlive: true }
        },
        {
            path: '/contact-us',
            component: ContactUs,
            meta: { keepAlive: true }
        },
        {
            path: '/media',
            component: Media,
            meta: { keepAlive: true }
        },
    ],
  });
  